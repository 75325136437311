<template>
  <div class="dashboard__container" v-if="user">
    <div class="dashboard__container--body" v-if="user">
      <div class="dashboard__container--body--col">
        <div>
          <h4 class="mb-3">Recent User Logins</h4>
          <div v-if="user && user.logins && user.logins.length >= 1" v-for="item in user.logins.slice(0, 10)" :key="item">
            <p>{{item | moment("MM/DD/YYYY hh:mm:ss a")}}</p>
          </div>
        </div>
      </div>
      <div class="dashboard__container--body--col">
        <div>
          <h4 class="mb-3">Historical Login Activity</h4>
          <p v-if="user">Logins in the last 24 hours: {{ loginsLast24Hours }}</p>
          <p v-if="user">Logins in the last 7 days: {{ loginsLast7Days }}</p>
          <p v-if="user">Logins in the last 30 days: {{ loginsLast30Days }}</p>
        </div>
      </div>
    </div>
    <!-- <Loader v-if="(userApplications && userApplications.length == 0)" /> -->

    </div>

</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import * as moment from 'moment'
// import UserEvents from '@/components/Profile/UserEvents.vue'

export default {
  props: ['user'],
  data() {
    return {
      lastLoginTime: null,
    };
  },
  name: 'userActivity',
  computed: {
    ...mapState(['currentUser', 'userProfile']),
    loginsLast24Hours() {
      if (this.user && this.user.logins && this.user.logins.length > 0) {
        const currentTime = moment();
        return this.user.logins.filter((timestamp) => {
          return currentTime.diff(moment.unix(timestamp), "hours") <= 24;
        }).length;
      }
      return 0;
    },
    loginsLast7Days() {
      if (this.user && this.user.logins && this.user.logins.length > 0) {
        const currentTime = moment();
        return this.user.logins.filter((timestamp) => {
          return currentTime.diff(moment.unix(timestamp), "days") <= 7;
        }).length;
      }
      return 0;
    },
    loginsLast30Days() {
      if (this.user && this.user.logins && this.user.logins.length > 0) {
        const currentTime = moment();
        return this.user.logins.filter((timestamp) => {
          return currentTime.diff(moment.unix(timestamp), "days") <= 30;
        }).length;
      }
      return 0;
    },
  },
  components: {
    Loader,
  },
}

</script>